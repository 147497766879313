<template>
  <div class="swap container">
    <IngredientsForm />
  </div>
</template>

<script>
  import IngredientsForm from '@/components/IngredientsForm.vue'

  export default {
    name: 'Swap',
    components: {
      IngredientsForm
    }
  }
</script>

<style scoped lang="scss">
    .swap {
        padding: 0 1em;
    }
</style>