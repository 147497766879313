<template>
    <div class="ingredient-search">
        <div class="form-intro">
            <p>
                Use the form below to quickly and easily find ingredient alternatives for your recipe.
            </p>
        </div>
        <form @submit.prevent="ingredientSubmit" id="ingredient-form" autocomplete="off">
            <div class="ingredients-label-wrap">
                <label for="ingredients" id="ingredients-label">Recipe ingredients: <span id="clear-ingredients-wrap" v-if="originalIngredients.length">(<span id="clear-ingredients" @click="clearOriginalIngredients">Clear ingredients</span>)</span></label>
            </div>
            <textarea v-model="originalIngredients" id="ingredients" :placeholder="'Enter your ingredients here, e.g.\n100g plain flour\n200ml cow\'s milk\n3 eggs\n2 cups of cornflour\n2 tsp vanilla extract\n4 tbsp peanut butter'" rows="10" cols="50"></textarea>
            <label for="has-allergies" id="has-allergies-label">
                Does the replacement ingredient need to cater to any allergies? (e.g. Gluten)
                <div class="checkbox-btn checkbox-btn--rounded">
                    <input type="checkbox" class="checkbox" :value="true" v-model="hasAllergies" id="has-allergies" />
                    <div class="toggler" data-label-checked="Yes" data-label-unchecked="No"></div>
                </div>
            </label>
            <div v-if="hasAllergies" class="allergies">
                <div class="options">
                    <label for="celery_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="celery_allergy" id="celery_allergy" /> Celery Allergy</label>
                    <label for="crustacean_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="crustacean_allergy" id="crustacean_allergy" /> Crustacean Allergy</label>
                    <label for="egg_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="egg_allergy" id="egg_allergy" /> Egg Allergy</label>
                    <label for="fish_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="fish_allergy" id="fish_allergy" /> Fish Allergy</label>
                    <label for="gluten_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="gluten_allergy" id="gluten_allergy" /> Gluten Allergy</label>
                    <label for="lactose_intolerance"><input type="checkbox" v-model="allergiesAndIntolerances" value="lactose_intolerance" id="lactose_intolerance" /> Lactose Intolerance</label>
                    <label for="lupin_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="lupin_allergy" id="lupin_allergy" /> Lupin Allergy</label>
                    <label for="milk_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="milk_allergy" id="milk_allergy" /> Milk Allergy</label>
                    <label for="mollusc_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="mollusc_allergy" id="mollusc_allergy" /> Mollusc Allergy</label>
                    <label for="mustard_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="mustard_allergy" id="mustard_allergy" /> Mustard Allergy</label>
                    <label for="peanut_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="peanut_allergy" id="peanut_allergy" /> Peanut Allergy</label>
                    <label for="sesame_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="sesame_allergy" id="sesame_allergy" /> Sesame Allergy</label>
                    <label for="soy_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="soy_allergy" id="soy_allergy" /> Soy Allergy</label>
                    <label for="tree_nut_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="tree_nut_allergy" id="tree_nut_allergy" /> Tree Nut Allergy</label>
                    <label for="wheat_allergy"><input type="checkbox" v-model="allergiesAndIntolerances" value="wheat_allergy" id="wheat_allergy" /> Wheat Allergy</label>
                </div>
            </div>
            <label for="has-dietary-preference" id="has-dietary-preferences-label">
                Does the replacement ingredient need to cater to any dietary preferences? (e.g. vegan)
                <div class="checkbox-btn checkbox-btn--rounded">
                    <input type="checkbox" class="checkbox" :value="true" v-model="hasDietaryPreference" id="has-dietary-preference" />
                    <div class="toggler" data-label-checked="Yes" data-label-unchecked="No"></div>
                </div>
            </label>
            <div v-if="hasDietaryPreference" class="dietary-preferences">
                <div class="options">
                    <label for="no_preference_diet"><input type="radio" v-model="dietaryPreference" value="" id="no_preference_diet" /> No Preference</label>
                    <label for="pescetarian_diet"><input type="radio" v-model="dietaryPreference" value="pescetarian" id="pescetarian_diet" /> Pescetarian Diet</label>
                    <label for="vegetarian_diet"><input type="radio" v-model="dietaryPreference" value="vegetarian" id="vegetarian_diet" /> Vegetarian Diet</label>
                    <label for="vegan_diet"><input type="radio" v-model="dietaryPreference" value="vegan" id="vegan_diet" /> Vegan Diet</label>
                </div>
            </div>
            <button type="submit" id="ingredient-submit" class="bubbly-button" @mouseover="buttonAnimate"><Octicon icon="search" :width="20" /> Find Alternatives</button>
        </form>
    </div>
</template>
<script>
    import Octicon from '@/components/Octicon.vue';
    export default {
        name: 'IngredientsForm',
        components: {
            Octicon
        },
        data() {
            return {}
        },
        methods: {
            clearOriginalIngredients() {
                this.$store.commit('updateOriginalIngredients', '');
            },
            ingredientSubmit() {
                this.$router.push({ name: 'Results' });
            },
            buttonAnimate(event) {
                if (event && event.target) {
                    event.target.classList.add('animate');
                    setTimeout(() => {
                        event.target.classList.remove('animate');
                    }, 700);
                }
            }
        },
        computed: {
            originalIngredients: {
                get() {
                    return this.$store.state.originalIngredients.join('\n');
                },
                set(value) {
                    this.$store.commit('updateOriginalIngredients', value);
                }
            },
            ingredientsList() {
                return this.$store.state.originalIngredients;
            },
            hasAllergies: {
                get() {
                    return this.$store.state.hasAllergies;
                },
                set(value) {
                    this.$store.commit('updateHasAllergies', value);
                }
            },
            allergiesAndIntolerances: {
                get() {
                    return this.$store.state.allergiesAndIntolerances;
                },
                set(value) {
                    this.$store.commit('updateAllergiesAndIntolerances', value);
                }
            },
            hasDietaryPreference: {
                get() {
                    return this.$store.state.hasDietaryPreference;
                },
                set(value) {
                    this.$store.commit('updateHasDietaryPreference', value);
                }
            },
            dietaryPreference: {
                get() {
                    return this.$store.state.dietaryPreference;
                },
                set(value) {
                    this.$store.commit('updateDietaryPreference', value);
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .options {
        text-align: left;
        label {
            box-sizing: border-box;
            padding: 5px 2%;
            display: inline-block;
            width: 100%;
            @media (min-width: $sm) {
                width: 50%;
            }
            @media (min-width: $md) {
                width: 33.3%;
            }
            @media (min-width: $lg) {
                width: 33.3%;
            }
            // text-align: left;
        }
    }
    /* Borrowed from: https://codepen.io/awolkers/pen/MPRJNJ */
    $btn-height: 40px;
    $btn-width: 100px;
    $btn-padding: 4px;
    .checkbox-btn {
        width: $btn-width;
        min-width: $btn-width;
        height: $btn-height;
        position: relative;
    }
    .checkbox {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: #f00;
        margin: 0;
        padding: 0;
        cursor: pointer;
        opacity: 0;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
    .toggler {
        background: #f00;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: #fff;
        line-height: 1;
        color: #fff;
        transition: background-color .15s ease-out;
        border-radius: 2px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        .checkbox-btn--rounded & {
            border-radius: $btn-height;
        }
        &::before {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: $btn-padding;
            left: $btn-padding;
            bottom: $btn-padding;
            padding: 0 12px;
            border-radius: 2px;
            background-color: #FF6138;
            content: attr(data-label-unchecked);
            transition: all .15s ease-out;
            min-width: $btn-height - (2 * $btn-padding);   
            .checkbox-btn--rounded & {
                border-radius: $btn-height;
            }
        }
        .checkbox:hover + & {
            background-color: #BAE59B;
        }
        .checkbox:active + &::before {
            padding: 0 20px;
        }
        .checkbox:checked + &::before {
            background-color: #79BD8F;
            content: attr(data-label-checked);
            left: calc(100% - #{$btn-padding});
            transform: translateX(-100%);
        }
    }
    /* End Borrowed from: https://codepen.io/awolkers/pen/MPRJNJ */

    label {
        text-align: left;
        font-size: 1.2em;
        cursor: pointer;
    }
    #has-allergies-label, #has-dietary-preferences-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1em 0;
        .checkbox-btn {
            margin-left: 1em;
        }
    }
    #ingredients {
        box-sizing: border-box;
        width: 100%;
        padding: 0.3em 0.5em;
        border: 0;
        margin-top: 0.2em;
        // margin-bottom: 1em;
    }
    // #ingredients-label {
    // }
    #clear-ingredients-wrap {
        display: inline-block;
        vertical-align: middle;
        font-size: 0.7em;
    }
    #clear-ingredients {
        text-decoration: underline;
        // color: $fuschia;
        cursor: pointer;
    }
</style>